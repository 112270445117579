import { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { fetchWithAuth } from '../utils/apiHelper';

export default function useSchedule(selectedDate) {
  const [employees, setEmployees] = useState([]);
  const [sucursalData, setSucursalData] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [services, setServices] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoadingEmpleados, setIsLoadingEmpleados] = useState(false);

  const fetchEmployeesAndSchedules = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchWithAuth('/horarios/empleados');
      setEmployees(data);
    } catch (error) {
      console.error('Failed to fetch employees and schedules:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchHorariosLocal = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchWithAuth('/horarios/sucursal/horas');
      setSucursalData(data);
    } catch (error) {
      console.error('Failed to fetch horarios local:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchAppointments = useCallback(async (date) => {
    setLoading(true);
    try {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const data = await fetchWithAuth(`/turnos/turno/${formattedDate}`);
      setAppointments(data);
    } catch (error) {
      console.error('Failed to fetch appointments:', error);
    } finally {
      setLoading(false);
    }
  }, [selectedDate]);

  const fetchServices = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchWithAuth('/services/');
      setServices(data);
    } catch (error) {
      console.error('Failed to fetch services:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPaymentMethods = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchWithAuth('/administracion/metodos/pago');
      setPaymentMethods(data);
    } catch (error) {
      console.error('Failed to fetch payment methods:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchEmpleados = useCallback(async () => {
    setIsLoadingEmpleados(true);
    try {
      const data = await fetchWithAuth('/empleados/');
      setEmpleados(data);
    } catch (error) {
      console.error('Error al obtener los empleados:', error);
      // Assuming you're using toast for error notification
      // toast.error('Error al obtener los empleados.');
    } finally {
      setIsLoadingEmpleados(false);
    }
  }, []);

  const fetchClientes = useCallback(async () => {
    try {
      const data = await fetchWithAuth('/clientes/');
      setClientes(data);
    } catch (error) {
      console.error('Error al obtener los clientes:', error);
      // Assuming you're using toast for error notification
      // toast.error('Error al obtener los clientes.');
    }
  }, []);

  const fetchAllData = useCallback(() => {
    fetchEmployeesAndSchedules();
    fetchHorariosLocal();
    fetchAppointments(selectedDate);
    fetchServices();
    fetchPaymentMethods();
    fetchEmpleados(); // Fetching empleados with the new state
    fetchClientes();  // Fetching clientes with the new state
  }, [fetchEmployeesAndSchedules, fetchHorariosLocal, fetchAppointments, fetchServices, fetchPaymentMethods, fetchEmpleados, fetchClientes, selectedDate]);

  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  const clearAppointments = useCallback(() => {
    setAppointments([]);
  }, []);

  return {
    employees,
    appointments,
    services,
    paymentMethods,
    clientes,
    empleados,
    loading,
    isLoadingEmpleados,
    sucursalData,
    fetchHorariosLocal,
    fetchEmployeesAndSchedules,
    fetchAppointments,
    fetchServices,
    fetchPaymentMethods,
    fetchEmpleados,
    fetchClientes,
    clearAppointments,
    fetchAllData
  };
}
