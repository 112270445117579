import React, { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWithAuth } from '../utils/apiHelper';

const ModalMovimientos = ({ isOpen, onClose }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOptionText, setSelectedOptionText] = useState('');
  const [amount, setAmount] = useState('');
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchEmployees();
    } else {
      resetForm();
    }
  }, [isOpen]);

  const fetchEmployees = async () => {
    try {
      const data = await fetchWithAuth('/horarios/empleados');
      setEmployees(data);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setSelectedOptionText(e.target.options[e.target.selectedIndex].text);
    if (['Vale a empleado', 'Pago de viaticos a empleado', 'Comision a empleado'].includes(e.target.value)) {
      setSelectedEmployee('');
    }
  };

  const handleEmployeeChange = (e) => {
    setSelectedEmployee(e.target.value);
  };

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    if (!inputValue || (!isNaN(inputValue) && parseFloat(inputValue) >= 0)) {
      setAmount(inputValue);
    }
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const payload = {
      descripcion: selectedOptionText,
      costo: parseFloat(amount),
      tipo: selectedOptionText,
      empleado_id: selectedEmployee || null,
      comentario: comment,
    };

    try {
      const result = await fetchWithAuth('/operaciones/crear/operacion', {
        method: 'POST',
        body: JSON.stringify(payload)
      });

      toast.success('Operación creada exitosamente');
      resetForm();
      onClose();
    } catch (error) {
      console.error('Error al enviar la operación:', error);
      toast.error(`Error al enviar la operación: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setSelectedOption('');
    setSelectedOptionText('');
    setAmount('');
    setSelectedEmployee('');
    setComment('');
  };

  const isFormValid = () => {
    if (['Vale a empleado', 'Pago de viaticos a empleado', 'Comision a empleado'].includes(selectedOption)) {
      return selectedOption && parseFloat(amount) > 0 && selectedEmployee;
    }
    return selectedOption && parseFloat(amount) > 0;
  };

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen px-4">
          <Transition.Child
            as={React.Fragment}
            enter="transition-opacity ease-in duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 bg-opacity-75"></div>
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0"
          >
            <div className="relative bg-gray-800 rounded-2xl shadow-xl max-w-lg w-full p-6 space-y-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-teal-500">Seleccionar Operación</h2>
                <button onClick={onClose} className="text-gray-300 hover:text-gray-100 transition-transform transform hover:scale-110">
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
              <div className="space-y-4">
                <div>
                  <label htmlFor="operacion" className="block text-lg font-semibold text-teal-400">Tipo de Operación</label>
                  <select
                    id="operacion"
                    name="operacion"
                    value={selectedOption}
                    onChange={handleOptionChange}
                    className="mt-2 block w-full pl-3 pr-10 py-3 text-lg text-gray-800 border-gray-300 focus:outline-none focus:ring-teal-500 focus:border-teal-500 rounded-lg shadow-sm bg-gray-100"
                  >
                    <option value="">Seleccione una opción</option>
                    <option value="Vale a empleado">Vale a empleado</option>
                    <option value="Retiro de efectivo">Retiro de efectivo</option>
                    <option value="Pago de viaticos a empleado">Pago de viaticos a empleado</option>
                    <option value="Ingreso de efectivo">Ingreso de efectivo</option>
                    <option value="Compra de insumos">Compra de insumos</option>
                    <option value="Comision a empleado">Comision a empleado</option>
                  </select>
                </div>
                {['Vale a empleado', 'Pago de viaticos a empleado', 'Comision a empleado'].includes(selectedOption) && (
                  <div>
                    <label htmlFor="empleado" className="block text-lg font-semibold text-teal-400">Seleccionar Empleado</label>
                    <select
                      id="empleado"
                      name="empleado"
                      value={selectedEmployee}
                      onChange={handleEmployeeChange}
                      className="mt-2 block w-full pl-3 pr-10 py-3 text-lg text-gray-800 border-gray-300 focus:outline-none focus:ring-teal-500 focus:border-teal-500 rounded-lg shadow-sm bg-gray-100"
                    >
                      <option value="">Seleccione un empleado</option>
                      {employees.map(employee => (
                        <option key={employee.empleado_id} value={employee.empleado_id}>
                          {employee.nombre} {employee.apellido}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div>
                  <label htmlFor="amount" className="block text-lg font-semibold text-teal-400">Monto</label>
                  <input
                    type="text"
                    id="amount"
                    name="amount"
                    value={amount}
                    onChange={handleAmountChange}
                    className="mt-2 block w-full pl-3 pr-10 py-3 text-lg text-gray-800 border-gray-300 focus:outline-none focus:ring-teal-500 focus:border-teal-500 rounded-lg shadow-sm bg-gray-100"
                    placeholder="Ingrese el monto"
                  />
                </div>
                <div>
                  <label htmlFor="comment" className="block text-lg font-semibold text-teal-400">Comentario</label>
                  <textarea
                    id="comment"
                    name="comment"
                    value={comment}
                    onChange={handleCommentChange}
                    className="mt-2 block w-full pl-3 pr-10 py-3 text-lg text-gray-800 border-gray-300 focus:outline-none focus:ring-teal-500 focus:border-teal-500 rounded-lg shadow-sm bg-gray-100"
                    placeholder="Ingrese un comentario"
                  />
                </div>
              </div>
              <div className="pt-6 flex justify-end">
                <button
                  onClick={handleSubmit}
                  className={`inline-flex justify-center py-3 px-6 border border-transparent shadow-lg text-lg font-semibold rounded-lg text-white ${
                    isFormValid() ? 'bg-teal-600 hover:bg-teal-700' : 'bg-gray-400 cursor-not-allowed'
                  } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 transition-transform transform hover:scale-105`}
                  disabled={!isFormValid() || isSubmitting}
                >
                  {isSubmitting ? 'Guardando...' : 'Guardar'}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
};

export default ModalMovimientos;
