import React, { useState, useEffect, useCallback } from 'react';
import { momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import BookingModal from './BookingModal';
import SinglePaymentModal from './SinglePaymentModal';
import useSchedule from './useSchedule';
import CalendarComponent from './CalendarComponent';
import ScheduleTable from './ScheduleTable';

moment.locale('es');
const localizer = momentLocalizer(moment);

function MyCalendar() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ employee: null, date: null, time: null });
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const {
    employees,
    appointments,
    loading,
    clientes,
    paymentMethods,
    services,
    empleados,
    sucursalData,
    fetchAllData, // This fetches all the necessary data at once
    clearAppointments,
  } = useSchedule(selectedDate);


  //console.log(paymentMethods)
  useEffect(() => {
    fetchAllData(); // Fetch all data at once
  }, [selectedDate, fetchAllData]);

  const handleNewBooking = () => {
    fetchAllData(); // Refresh all data after a new booking
  };

  const openModal = useCallback((employee, date, time) => {
    setModalData({ employee, date, time });
    setIsModalOpen(true);
  }, []);

  const handleBookingClick = (employee, time) => {
    openModal(employee, moment(selectedDate).format('YYYY-MM-DD'), time);
  };

  const handleSelectSlot = ({ start }) => {
    setSelectedDate(start);
    clearAppointments();
    fetchAllData(); // Refresh all data when a new date is selected
  };

  const dayPropGetter = useCallback((date) => {
    const isSelectedDate = moment(date).isSame(selectedDate, 'day');
    return {
      style: {
        backgroundColor: isSelectedDate ? '#8ee48e' : '',
        color: isSelectedDate ? 'white' : 'black'
      }
    };
  }, [selectedDate]);

  return (
    <div className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 ${loading ? 'cursor-progress' : 'cursor-default'}`}>
      <CalendarComponent
        localizer={localizer}
        onSelectSlot={handleSelectSlot}
        dayPropGetter={dayPropGetter}
        selectedDate={selectedDate}
      />

      <div className="flex justify-between mt-4">
        <button
          className="bg-teal-400 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded-lg transition-transform transform hover:scale-105"
          onClick={() => setIsPaymentModalOpen(true)}
        >
          Crear Pago
        </button>
      </div>

      <div className="mt-4 bg-teal-600 text-center text-xl font-semibold p-4 rounded-lg shadow-lg border-2 border-teal-200">
        <span className="text-white">
          Turnos del día {moment(selectedDate).format('dddd D [del mes de] MMMM [del año] YYYY')}
        </span>
      </div>

      <ScheduleTable
        sucursal={sucursalData}
        appointments={appointments}
        employees={employees}
        clientes={clientes}
        paymentMethods={paymentMethods}
        empleados={empleados}
        servicios={services}
        dia={moment(selectedDate).format('dddd')}
        onBookingClick={handleBookingClick}
        updateAppointments={handleNewBooking}
      />

      {isModalOpen && (
        <BookingModal
          isOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          data={modalData}
          updateAppointments={handleNewBooking}
        />
      )}

      {isPaymentModalOpen && (
        <SinglePaymentModal
          isOpen={isPaymentModalOpen}
          onClose={() => setIsPaymentModalOpen(false)}
          loadingState={false}
          paymentMethods={paymentMethods}
          empleados={empleados}
          clientes={clientes}
          servicios={services}
          updateAppointments={handleNewBooking}
        />
      )}
    </div>
  );
}

export default MyCalendar;
