import React, { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { fetchWithAuth } from '../utils/apiHelper';

const OperacionesPagosModal = ({ isOpen, onRequestClose }) => {
  const [data, setData] = useState({ operaciones: [], pagos: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalEnCaja, setTotalEnCaja] = useState(0);
  const [maxCajaAmount, setMaxCajaAmount] = useState(0);
  const [alertVisible, setAlertVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState(new Set());

  useEffect(() => {
    if (isOpen) {
      const fetchData = async () => {
        try {
          const result = await fetchWithAuth('/operaciones/operationsypagos');
          setData(result);
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };

      const fetchMontoActualEnCaja = async () => {
        try {
          const result = await fetchWithAuth('/caja/montoencaja');
          setTotalEnCaja(result.total_en_caja);
        } catch (err) {
          console.error('Error fetching monto actual en caja:', err);
        }
      };

      const fetchMaxCajaAmount = async () => {
        try {
          const result = await fetchWithAuth('/caja/max/amount');
          setMaxCajaAmount(result.max_amount);
        } catch (err) {
          console.error('Error fetching max caja amount:', err);
        }
      };

      fetchMontoActualEnCaja();
      fetchMaxCajaAmount();
      fetchData();
    }
  }, [isOpen]);

  useEffect(() => {
    if (totalEnCaja > maxCajaAmount) {
      setAlertVisible(true);
    } else {
      setAlertVisible(false);
    }
  }, [totalEnCaja, maxCajaAmount]);

  if (!isOpen) {
    return null;
  }

  const handleCheckboxChange = (id) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set(prevSelectedItems);
      if (newSelectedItems.has(id)) {
        newSelectedItems.delete(id);
      } else {
        newSelectedItems.add(id);
      }
      return newSelectedItems;
    });
  };

  const handleMarkCompleted = () => {
    // Implement logic to mark items as completed
    setSelectedItems(new Set()); // Clear selection
  };

  const formatTime = (timeString) => {
    return new Date(`1970-01-01T${timeString}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const combinedData = [
    ...data.operaciones.map(op => ({ ...op, type: 'operacion', time: op.hora, id: op.operacion_id })),
    ...data.pagos.map(pg => ({ ...pg, type: 'pago', time: pg.hora_pago, id: pg.pago_id }))
  ].sort((a, b) => new Date(`1970-01-01T${a.time}`) - new Date(`1970-01-01T${b.time}`));

  const excessAmount = totalEnCaja - maxCajaAmount;

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen px-4">
          <Transition.Child
            as={React.Fragment}
            enter="transition-opacity ease-in duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 bg-opacity-80"></div>
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0"
          >
            <div className="relative bg-gray-800 rounded-2xl shadow-xl max-w-3xl w-full p-3 m-9 space-y-6 text-white">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-3xl font-extrabold text-teal-500">Operaciones y Pagos de Hoy</h2>
                <button onClick={onRequestClose} className="text-white hover:text-red-400 transition-transform transform hover:scale-110">
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
              <div className="mb-4">
                <h3 className="text-xl font-bold text-yellow-400">Monto actual en caja: <span className="text-white">${totalEnCaja}</span></h3>
                <h3 className="text-xl font-bold text-yellow-400">Monto máximo permitido en caja: <span className="text-white">${maxCajaAmount}</span></h3>
                {totalEnCaja > maxCajaAmount && (
                  <h3 className="text-xl font-bold text-red-500">Exceso de monto en caja: <span className="text-white">${excessAmount}</span></h3>
                )}
              </div>
              {alertVisible && (
                <div className="mb-4 p-4 bg-red-600 text-white text-center rounded-lg">
                  <strong>Alerta:</strong> El monto en caja supera el límite permitido. Es necesario hacer un retiro de dinero.
                </div>
              )}
              {loading ? (
                <p className="text-center text-lg text-gray-300">Cargando...</p>
              ) : error ? (
                <p className="text-center text-lg text-red-400">Error: {error}</p>
              ) : (
                <>
                  <div className="overflow-y-auto">
                    <table className="table-auto w-full text-sm text-left text-gray-200">
                      <thead className="text-xs uppercase bg-gray-700 text-gray-400">
                        <tr>
                          <th scope="col" className="px-4 py-3">Seleccionar</th>
                          <th scope="col" className="px-4 py-3">Detalle</th>
                          <th scope="col" className="px-4 py-3">Comentario</th>
                          <th scope="col" className="px-4 py-3">Importe</th>
                          <th scope="col" className="px-4 py-3">Usuario</th>
                          <th scope="col" className="px-4 py-3">Empleado</th>
                          <th scope="col" className="px-4 py-3">Método de Pago</th>
                          <th scope="col" className="px-4 py-3">Hora</th>
                        </tr>
                      </thead>
                      <tbody>
                        {combinedData.map((item) => (
                          <tr
                            key={item.id}
                            className={`border-b border-gray-700 ${item.type === 'pago' ? 'bg-gray-800' : 'bg-gray-700'} hover:bg-gray-600 transition-colors`}
                          >
                            <td className="px-4 py-3">
                              <input
                                type="checkbox"
                                checked={selectedItems.has(item.id)}
                                onChange={() => handleCheckboxChange(item.id)}
                                className="form-checkbox h-4 w-4 text-teal-500 transition duration-150 ease-in-out"
                              />
                            </td>
                            <td className="px-4 py-3">{item.type === 'operacion' ? item.descripcion : item.servicio_nombre}</td>
                            <td className="px-4 py-3">{item.comentario || 'N/A'}</td>
                            <td className="px-4 py-3">${item.type === 'operacion' ? item.costo : item.monto}</td>
                            <td className="px-4 py-3">{item.usuario_operacion || item.usuario_pago}</td>
                            <td className="px-4 py-3">{item.empleado_operacion || item.empleado_nombre || 'N/A'}</td>
                            <td className="px-4 py-3">{item.metodo_pago || 'N/A'}</td>
                            <td className="px-4 py-3">{formatTime(item.time)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="flex justify-end mt-4">
                    <button
                      onClick={handleMarkCompleted}
                      className="px-4 py-2 bg-teal-600 text-white rounded-full hover:bg-teal-700 transition-transform transform hover:scale-105"
                    >
                      Marcar como Completado
                    </button>
                  </div>
                </>
              )}
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
};

export default OperacionesPagosModal;
