import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Login from './Components/Login';
import SignUp from './Components/SignUp';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import Servicios from './Components/Servicios';
import Clientes from './Components/Clientes';
import HorariosEmpleados from './Components/HorariosEmpleados';
import Empleados from './Components/Empleados';
import { ToastContainer } from 'react-toastify';
import Comisiones from './Components/Comisiones';
import DataList from './Components/DataList';
import { fetchWithAuth } from './utils/apiHelper';
import LiquidacionesResumen from './Components/LiquidacionesResumen';
import ConfirmarTurno from './Components/ConfirmarTurno';
import PaymentMethods from './Components/PaymentMethods';
import ArqueoCaja from './Components/ArqueoCaja';
import RetirosDinero from './Components/RetirosDinero';
import ClienteDetalles from './Components/ClienteDetalles'; // Importar el nuevo componente
import ServerInfo from './Components/ServerInfo';




function AppContent() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();


  useEffect(() => {
    verifyToken();
    
  }, []);

  const ProtectedRoute = ({ children }) => {
    return isAuthenticated ? children : <Navigate to="/login" replace />;
  };

  const verifyToken = async () => {
    const token = localStorage.getItem('token');
    const sucursalNombre = localStorage.getItem('sucursalNombre');
    // Set the document title to the sucursalNombre if it exists, otherwise set it to an empty string
    document.title = sucursalNombre || 'Turnos';
    if (token) {
      try {
        const data = await fetchWithAuth('/auth/verify-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (data) {
          setIsAuthenticated(true);
        } else {
          console.error('Token inválido o expirado');
          setIsAuthenticated(false);
          localStorage.clear();
        }
      } catch (error) {
        console.error('Error al verificar el token:', error);
        setIsAuthenticated(false);
        localStorage.clear();
      }
    } else {
      setIsAuthenticated(false);
    }
    setLoading(false);
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  const isConfirmationRoute = location.pathname.startsWith('/confirmar/turno/');

  return (
    <div className={`app-container ${!isConfirmationRoute ? 'bg-backgroundColor' : ''}`}>
      <ToastContainer position="top-right" autoClose={1500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      {!isConfirmationRoute && isAuthenticated && <Navbar setIsAuthenticated={setIsAuthenticated} />}
      <div className={`${!isConfirmationRoute ? 'content-container' : ''}`}>
        <Routes>
          <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="/server-data" element={<ProtectedRoute><ServerInfo /></ProtectedRoute>} />
          <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/servicios" element={<ProtectedRoute><Servicios /></ProtectedRoute>} />
          <Route path="/clientes" element={<ProtectedRoute><Clientes /></ProtectedRoute>} />
          <Route path="/gestion/horarios" element={<ProtectedRoute><HorariosEmpleados /></ProtectedRoute>} />
          <Route path="/caja/arqueo" element={<ProtectedRoute><ArqueoCaja /></ProtectedRoute>} />
          <Route path="/gestion/empleados" element={<ProtectedRoute><Empleados /></ProtectedRoute>} />
          <Route path="/gestion/metodos/pago" element={<ProtectedRoute><PaymentMethods /></ProtectedRoute>} />
          <Route path="/gestion/comisiones" element={<ProtectedRoute><Comisiones /></ProtectedRoute>} />
          <Route path="/trabajos/Caja" element={<ProtectedRoute><RetirosDinero /></ProtectedRoute>} />
          <Route path="/caja/fuerte" element={<ProtectedRoute><DataList /></ProtectedRoute>} />
          <Route path="/Gestion/liquidacion" element={<ProtectedRoute><LiquidacionesResumen /></ProtectedRoute>} />
          <Route path="/confirmar/turno/:turno_id" element={<ConfirmarTurno />} />
          <Route path="/detalles/cliente/:id" element={<ProtectedRoute><ClienteDetalles /></ProtectedRoute>} /> {/* Nueva ruta */}
          
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
