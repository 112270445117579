import React, { useEffect, useState } from 'react';
import { fetchWithAuth } from '../utils/apiHelper';

const ServerInfo = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const result = await fetchWithAuth('/server-info');
      setData(result);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (error) return <div className="text-red-500 p-4">{error.message}</div>;
  if (!data) return <div className="text-center text-gray-500 p-4">Loading...</div>;

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg max-w-4xl mx-auto mt-6">
      <h2 className="text-3xl font-bold mb-6 text-center">Server Information</h2>
      <div className="space-y-6">

        {/* General Info Section */}
        <Section title="General Info">
          <InfoItem label="Uptime" value={`${data.uptime} seconds`} />
          <InfoItem label="Total Memory" value={`${(data.memoryUsage.total / (1024 ** 3)).toFixed(2)} GB`} />
          <InfoItem label="Used Memory" value={`${(data.memoryUsage.used / (1024 ** 3)).toFixed(2)} GB`} />
          <InfoItem label="Free Memory" value={`${(data.memoryUsage.free / (1024 ** 3)).toFixed(2)} GB`} />
          <InfoItem label="CPU Load" value={`${data.cpuUsage.currentLoad.toFixed(2)}%`} />
          <InfoItem label="CPU Temperature" value={data.cpuTemperature.main ? `${data.cpuTemperature.main} °C` : 'N/A'} />
        </Section>

        {/* Disk Usage Section */}
        <Section title="Disk Usage">
          {data.diskUsage.map((disk, index) => (
            <InfoItem
              key={index}
              label={`${disk.fs}`}
              value={`${(disk.used / (1024 ** 3)).toFixed(2)} GB used of ${(disk.size / (1024 ** 3)).toFixed(2)} GB`}
            />
          ))}
        </Section>

        {/* Security Section */}
        <Section title="Security and Monitoring">
          <InfoItem label="Firewall Status" value={data.firewallStatus.map(fw => fw.name).join(', ')} />
          <InfoItem label="Failed Login Attempts" value={formatLogEntries(data.failedLogins)} />
          <InfoItem label="Recent SSH Attempts" value={formatLogEntries(data.sshAttempts)} />
        </Section>

        {/* System Messages Section */}
        <Section title="System Messages">
          {data.dmesgLogs.length > 0 ? (
            <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-auto max-h-64">
              {data.dmesgLogs.map((log, index) => (
                <LogEntry key={index} log={log} />
              ))}
            </pre>
          ) : (
            <p className="text-gray-500">No system messages available.</p>
          )}
        </Section>

        {/* Network Section */}
        <Section title="Network and Connections">
          {data.networkInterfaces.map((iface, index) => (
            <InfoItem key={index} label={`${iface.iface}`} value={`IP: ${iface.ip4}`} />
          ))}
          <InfoItem
            label="Recent Connections"
            value={
              Array.isArray(data.recentConnections)
                ? data.recentConnections.map(conn => `${conn.host} - ${conn.status}`).join(', ')
                : 'No recent connections data available'
            }
          />
        </Section>

        {/* Logs Section */}
        <Section title="Logs">
          {data.logs.length > 0 ? (
            <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-auto max-h-64">
              {data.logs.map((log, index) => (
                <LogEntry key={index} log={log} />
              ))}
            </pre>
          ) : (
            <p className="text-gray-500">No logs available.</p>
          )}
        </Section>
      </div>
    </div>
  );
};

const Section = ({ title, children }) => (
  <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
    <h3 className="text-lg font-semibold mb-2 border-b pb-2">{title}</h3>
    <div className="space-y-2">{children}</div>
  </div>
);

const InfoItem = ({ label, value }) => (
  <div className="flex justify-between items-center">
    <span className="font-medium">{label}:</span>
    <span className="text-gray-700">{value}</span>
  </div>
);

// Component to format individual log entries
const LogEntry = ({ log }) => {
  const { timestamp, message, srcIp, dstIp, spt, dpt } = parseLogEntry(log);
  return (
    <div className="mb-2 p-2 border-b border-gray-600">
      <div className="text-blue-300">{timestamp}</div>
      <div className="text-green-400">{message}</div>
      <div className="text-gray-400">
        Source IP: {srcIp} (Port: {spt}) → Destination IP: {dstIp} (Port: {dpt})
      </div>
    </div>
  );
};

// Helper function to parse log entries into readable parts
const parseLogEntry = (log) => {
  const logRegex = /\[(.*?)\] (.*?) (.*?) - (.*?)(SRC=(.*?) DST=(.*?) LEN=(\d+) .* SPT=(\d+) DPT=(\d+).*)/;
  const match = log.match(logRegex);

  if (!match) {
    return {
      timestamp: 'Unknown Time',
      message: log,
      srcIp: 'N/A',
      dstIp: 'N/A',
      spt: 'N/A',
      dpt: 'N/A',
    };
  }

  const [, timestamp, , , message, , srcIp, dstIp, , spt, dpt] = match;

  return {
    timestamp: new Date(timestamp).toLocaleString(),
    message,
    srcIp,
    dstIp,
    spt,
    dpt,
  };
};

// Function to format an array of log entries
const formatLogEntries = (logs) => {
  if (!logs || !logs.length) return 'No entries available.';
  return logs.map((log, index) => <LogEntry key={index} log={log} />);
};

export default ServerInfo;
