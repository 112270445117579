import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import { CheckCircleIcon, XCircleIcon, ClockIcon, UserIcon, ClipboardDocumentListIcon, CreditCardIcon, PhoneIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline'; 
import PaymentModal from './PaymentModal'; 
import ConfirmCancelModal from './ConfirmCancelModal'; 
import { fetchWithAuth } from '../utils/apiHelper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppointmentDetails = ({ appointment, onConfirm, onCancel, onPay, loadingState, className, empleados, paymentMethods, servicios }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [clientArrived, setClientArrived] = useState(appointment.cliente_llego);
  const [sameDayTurnos, setSameDayTurnos] = useState([]);

  const baseUrl = `${process.env.REACT_APP_API_HOST}/confirmar/turno/${appointment.turno_id}`;
  const formattedTime = appointment.hora.substr(0, 5);
  const formattedDate = appointment.fecha.split('-').slice(1).reverse().join('/');
  const mensaje = `Hola ${appointment.cliente_nombre}!, te recordamos que tienes un turno para ${appointment.servicio_nombre} para el dia ${formattedDate} a las ${formattedTime} hs. Haz click en este link para confirmar o cancelarlo. Gracias! ${baseUrl}`;
  const mensajeWhatsApp = encodeURI(mensaje);
  const HrefLink = `whatsapp://send/?phone=${appointment.telefono_sufijo}${appointment.telefono}&text=${mensajeWhatsApp}&source=&data=`;

  useEffect(() => {
    const fetchSameDayTurnos = async () => {
      try {
        const data = await fetchWithAuth(`/turnos/cliente/${appointment.cliente_id}/fecha/${appointment.fecha}`, {
          method: 'GET',
        });
        setSameDayTurnos(data.filter(turno => turno.turno_id !== appointment.turno_id));
      } catch (error) {
        console.error('Error fetching same day turnos:', error);
        toast.error('Error al obtener los turnos del mismo día.');
      }
    };

    fetchSameDayTurnos();
  }, [appointment]);

  const handlePayClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirmCancel = async (reason) => {
    setCancelReason(reason);
    await onCancel(appointment.turno_id, reason);
    setIsCancelModalOpen(false);
  };

  const handleClientArrival = async () => {
    try {
      await fetchWithAuth(`/turnos/confirmar/cliente/${appointment.turno_id}`, {
        method: 'PUT',
      });
      setClientArrived(true);
      toast.success('Cliente marcado como llegado.');
    } catch (error) {
      console.error('Error confirming client arrival:', error);
      toast.error('Error al confirmar la llegada del cliente.');
    }
  };

  const getStatusColor = (estado) => {
    switch (estado) {
      case 'Reservado':
        return 'bg-teal-100 border-teal-500';
      case 'Confirmado':
        return 'bg-green-100 border-green-500';
      case 'Pagado':
        return 'bg-orange-100 border-orange-500';
      default:
        return 'bg-gray-100 border-gray-500';
    }
  };

  return (
    <div className={`h-full w-full p-4 flex flex-col justify-between rounded-lg shadow-md border-2 ${getStatusColor(appointment.estado)} ${className}`}>
      <div className="space-y-3 flex-grow">
        <div className="flex items-center space-x-3">
          <UserIcon className="h-6 w-6 text-teal-700" />
          <span className="text-teal-900 font-semibold text-sm">Cliente:</span>
          <Link
            to={`/detalles/cliente/${appointment.cliente_id}`}
            className="text-teal-800 text-sm underline hover:text-teal-900"
          >
            {appointment.cliente_nombre} {appointment.cliente_apellido}
          </Link>
        </div>
        <div className="flex items-center space-x-3">
          <PhoneIcon className="h-6 w-6 text-teal-700" />
          <span className="text-teal-900 font-semibold text-sm">Teléfono:</span>
          <a
            href={HrefLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-700 flex items-center space-x-1 text-sm"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="h-5 w-5 text-gray-600">
              <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
            </svg>
            <span>{appointment.cliente_telefono}</span>
          </a>
        </div>
        <div className="flex items-center space-x-3">
          <ClipboardDocumentCheckIcon className="h-6 w-6 text-teal-700" />
          <span className="text-teal-900 font-semibold text-sm">Servicio:</span>
          <span className="text-teal-800 text-sm">{appointment.servicio_nombre}</span>
        </div>
        <div className="flex items-center space-x-3">
          <ClockIcon className="h-6 w-6 text-teal-700" />
          <span className="text-teal-900 font-semibold text-sm">Duración:</span>
          <span className="text-teal-800 text-sm">{appointment.duracion.hours ? `${appointment.duracion.hours} h` : ''} {appointment.duracion.minutes ? `${appointment.duracion.minutes} min` : ''}</span>
        </div>
        <div className="flex items-center space-x-3">
          <CheckCircleIcon className="h-6 w-6 text-teal-700" />
          <span className="text-teal-900 font-semibold text-sm">Estado:</span>
          <span className={`px-2 py-1 text-sm font-semibold text-white rounded-lg ${appointment.estado === 'Reservado' ? 'bg-teal-600' : appointment.estado === 'Confirmado' ? 'bg-green-600' : appointment.estado === 'Pagado' ? 'bg-orange-600' : 'bg-red-600'}`}>
            {appointment.estado}
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <ClipboardDocumentListIcon className="h-6 w-6 text-teal-700" />
          <span className="text-teal-900 font-semibold text-sm">Comentario:</span>
          <span className="text-teal-800 text-sm">{appointment.comentario || 'Sin comentario'}</span>
        </div>

        {clientArrived && (
          <div className="flex items-center space-x-3">
            <CheckCircleIcon className="h-6 w-6 text-green-600" />
            <span className="text-green-700 font-semibold text-sm">Cliente ha llegado</span>
          </div>
        )}
      </div>

      {appointment.estado === 'Reservado' && (
        <div className="pt-3 flex space-x-3">
          <button 
            onClick={() => onConfirm(appointment.turno_id)}
            className={`flex items-center justify-center bg-teal-600 hover:bg-teal-700 text-white font-bold py-2 px-3 rounded-lg transition duration-200 transform ${loadingState === 'confirming' ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105'}`}
            disabled={loadingState === 'confirming'}
          >
            {loadingState === 'confirming' ? (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v4m0 8v4m8-8h-4m-8 0H4" />
                </svg>
                <span className="text-xs">Confirmando...</span>
              </>
            ) : (
              <>
                <CheckCircleIcon className="h-5 w-5" />
                <span className="text-xs">Confirmar</span>
              </>
            )}
          </button>
          <button 
            onClick={() => setIsCancelModalOpen(true)}
            className={`flex items-center justify-center bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-3 rounded-lg transition duration-200 transform ${loadingState === 'canceling' ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105'}`}
            disabled={loadingState === 'canceling'}
          >
            {loadingState === 'canceling' ? (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                <span className="text-xs">Cancelando...</span>
              </>
            ) : (
              <>
                <XCircleIcon className="h-5 w-5" />
                <span className="text-xs">Cancelar</span>
              </>
            )}
          </button>
        </div>
      )}

      {appointment.estado === 'Confirmado' && (
        <div className="pt-3 flex flex-col space-y-3">
          <div className="flex space-x-3">
            <button 
              onClick={handlePayClick}
              className={`flex items-center justify-center bg-orange-600 hover:bg-orange-700 text-white font-bold py-2 px-3 rounded-lg transition duration-200 transform ${loadingState === 'paying' ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105'}`}
              disabled={loadingState === 'paying'}
            >
              {loadingState === 'paying' ? (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v4m0 8v4m8-8h-4m-8 0H4" />
                  </svg>
                  <span className="text-xs">Procesando...</span>
                </>
              ) : (
                <>
                  <CreditCardIcon className="h-5 w-5" />
                  <span className="text-xs">Pagar</span>
                </>
              )}
            </button>
            {!clientArrived ? (
              <button 
                onClick={handleClientArrival}
                className="flex items-center justify-center bg-teal-600 hover:bg-teal-700 text-white font-bold py-2 px-3 rounded-lg transition duration-200 transform hover:scale-105"
                disabled={clientArrived}
              >
                <CheckCircleIcon className="h-5 w-5" />
                <span className="text-xs">Cliente Llegó</span>
              </button>
            ) : (
              <button 
                onClick={() => setIsCancelModalOpen(true)}
                className={`flex items-center justify-center bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-3 rounded-lg transition duration-200 transform ${loadingState === 'canceling' ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105'}`}
                disabled={loadingState === 'canceling'}
              >
                {loadingState === 'canceling' ? (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <span className="text-xs">Cancelando...</span>
                  </>
                ) : (
                  <>
                    <XCircleIcon className="h-5 w-5" />
                    <span className="text-xs">Cancelar</span>
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      )}

      <PaymentModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        appointment={appointment} 
        onPay={onPay}
        empleados={empleados}
        paymentMethods={paymentMethods}
        servicios={servicios}
        loadingState={loadingState} 
        sameDayTurnos={sameDayTurnos}
      />
      <ConfirmCancelModal
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        onConfirm={handleConfirmCancel}
      />
    </div>
  );
};

export default AppointmentDetails;
