import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWithAuth } from '../utils/apiHelper';

function TimeSelector({ label, value, onChange, options, disabled }) {
    return (
        <div className="flex flex-col">
            <label className="text-sm font-semibold text-gray-700 mb-1">{label}</label>
            <select
                className={`appearance-none bg-white border border-gray-300 rounded-md shadow-sm p-2 text-base focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 ${disabled ? 'bg-gray-100 cursor-not-allowed' : '00'}`}
                value={value}
                onChange={onChange}
                disabled={disabled}
            >
                {options.map(option => (
                    <option key={option} value={option}>{option}</option>
                ))}
            </select>
        </div>
    );
}

function HorariosEmpleados() {
    const [empleados, setEmpleados] = useState([]);
    const [selectedEmpleado, setSelectedEmpleado] = useState(null);
    const [horarios, setHorarios] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [storeHours, setStoreHours] = useState({
        openingHour: '00',
        openingMinute: '00',
        closingHour: '00',
        closingMinute: '00'
    });
    const diasSemana = ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'];

    useEffect(() => {
        setIsLoading(true);
        fetchEmpleados();
        fetchStoreHours();
    }, []);

    const fetchEmpleados = async () => {
        try {
            const data = await fetchWithAuth('/horarios/empleados');
            setEmpleados(data);
        } catch (error) {
            console.error('Error al obtener los empleados y horarios:', error);
            toast.error('Error al cargar los datos de los empleados.');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchStoreHours = async () => {
        try {
            const data = await fetchWithAuth('/horarios/sucursal/horas');
            const { hora_inicio, hora_fin } = data;
            const [openingHour, openingMinute] = hora_inicio.split(':');
            const [closingHour, closingMinute] = hora_fin.split(':');
            setStoreHours({
                openingHour,
                openingMinute,
                closingHour,
                closingMinute
            });
        } catch (error) {
            console.error('Error al obtener las horas de la sucursal:', error);
            toast.error('Error al obtener las horas de la sucursal.');
        }
    };

    const handleEmpleadoChange = (e) => {
        const empleadoId = e.target.value;
        if (empleadoId) {
            const empleado = empleados.find(emp => emp.empleado_id.toString() === empleadoId);
            setSelectedEmpleado(empleado);
            initializeHorariosForEmpleado(empleado);
        } else {
            // Reset if "Seleccionar..." is selected
            setSelectedEmpleado(null);
            setHorarios({});
        }
    };

    const initializeHorariosForEmpleado = (empleado) => {
        const horariosData = diasSemana.reduce((acc, dia) => {
            const horario = empleado ? empleado.horarios.find(h => h.dia_semana === dia) : null;
            acc[dia] = horario ? {
                ...horario,
                hora_inicio: horario.hora_inicio.split(':')[0],
                minuto_inicio: horario.hora_inicio.split(':')[1],
                hora_fin: horario.hora_fin.split(':')[0],
                minuto_fin: horario.hora_fin.split(':')[1],
                active: true
            } : {
                hora_inicio: '00',
                minuto_inicio: '00',
                hora_fin: '00',
                minuto_fin: '00',
                active: false
            };
            return acc;
        }, {});
        setHorarios(horariosData);
    };

    const handleTimeChange = (dia, campo, valor) => {
        const updatedHorarios = { ...horarios };
        const currentValues = updatedHorarios[dia];

        if (campo.includes('inicio')) {
            const startTime = parseInt(currentValues.hora_inicio) * 60 + parseInt(currentValues.minuto_inicio);
            const endTime = parseInt(currentValues.hora_fin) * 60 + parseInt(currentValues.minuto_fin);
            const newStartTime = campo === 'hora_inicio' ? parseInt(valor) * 60 + parseInt(currentValues.minuto_inicio) : parseInt(currentValues.hora_inicio) * 60 + parseInt(valor);

            if (newStartTime >= endTime) {
                toast.error('La hora de inicio debe ser menor que la hora de fin.');
                return;
            }
        } else if (campo.includes('fin')) {
            const startTime = parseInt(currentValues.hora_inicio) * 60 + parseInt(currentValues.minuto_inicio);
            const newEndTime = campo === 'hora_fin' ? parseInt(valor) * 60 + parseInt(currentValues.minuto_fin) : parseInt(currentValues.hora_fin) * 60 + parseInt(valor);

            if (newEndTime <= startTime) {
                toast.error('La hora de fin debe ser mayor que la hora de inicio.');
                return;
            }
        }

        updatedHorarios[dia] = {
            ...currentValues,
            [campo]: valor
        };

        setHorarios(updatedHorarios);
    };

    const handleStoreTimeChange = (campo, valor) => {
        setStoreHours(prev => ({
            ...prev,
            [campo]: valor
        }));
    };

    const handleDiaChange = (dia, isActive) => {
        setHorarios(prev => ({
            ...prev,
            [dia]: {
                ...prev[dia],
                active: isActive,
            },
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedEmpleado) {
            toast.error('Por favor, selecciona un empleado.');
            return;
        }

        const horariosArray = Object.entries(horarios)
            .filter(([_, data]) => data.active)
            .map(([dia, data]) => ({
                empleado_id: selectedEmpleado.empleado_id,
                dia_semana: dia,
                hora_inicio: `${data.hora_inicio}:${data.minuto_inicio}`,
                hora_fin: `${data.hora_fin}:${data.minuto_fin}`,
            }));

        setIsLoading(true);

        try {
            await fetchWithAuth('/horarios/update', {
                method: 'POST',
                body: JSON.stringify(horariosArray)
            });
            toast.success('Horarios guardados exitosamente.');
        } catch (error) {
            console.error('Error al guardar los horarios:', error);
            toast.error('Error al guardar los horarios.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleStoreHoursSubmit = async () => {
        const storeHoursData = {
            openingHour: `${storeHours.openingHour}:${storeHours.openingMinute}`,
            closingHour: `${storeHours.closingHour}:${storeHours.closingMinute}`
        };

        setIsLoading(true);

        try {
            await fetchWithAuth('/horarios/sucursal/horas', {
                method: 'POST',
                body: JSON.stringify(storeHoursData)
            });
            toast.success('Horas de la sucursal guardadas exitosamente.');
        } catch (error) {
            console.error('Error al guardar las horas de la sucursal:', error);
            toast.error('Error al guardar las horas de la sucursal.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="mb-4 p-4 rounded-lg shadow-md bg-blue-100 border-l-4 border-blue-500">
                <h2 className="text-2xl font-semibold mb-4">Horarios del Local</h2>
                <div className="flex items-center space-x-2">
                    <TimeSelector
                        label="Hora Inicio"
                        value={storeHours.openingHour}
                        onChange={e => handleStoreTimeChange('openingHour', e.target.value)}
                        options={Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'))}
                    />
                    <TimeSelector
                        label="Minuto Inicio"
                        value={storeHours.openingMinute}
                        onChange={e => handleStoreTimeChange('openingMinute', e.target.value)}
                        options={['00', '30']}
                    />
                    <span className="text-xl mx-4">a</span>
                    <TimeSelector
                        label="Hora Fin"
                        value={storeHours.closingHour}
                        onChange={e => handleStoreTimeChange('closingHour', e.target.value)}
                        options={Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'))}
                    />
                    <TimeSelector
                        label="Minuto Fin"
                        value={storeHours.closingMinute}
                        onChange={e => handleStoreTimeChange('closingMinute', e.target.value)}
                        options={['00', '30']}
                    />
                </div>
                <button
                    onClick={handleStoreHoursSubmit}
                    className="mt-4 w-full py-2 bg-green-600 text-white rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150"
                >
                    Guardar Horas de la Tienda
                </button>
            </div>
            
            <h1 className="text-3xl font-bold mb-6 text-center">Gestión de Horarios de Empleados</h1>
            {isLoading && <div className="flex justify-center items-center">
                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Seleccionar Empleado</label>
                <select
                    onChange={handleEmpleadoChange}
                    className="mt-1 block w-full p-3 border-2 border-indigo-500 rounded-lg shadow-lg focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg bg-white text-indigo-700 font-semibold"
                >
                    <option value="">Seleccionar...</option>
                    {empleados.map(empleado => (
                        <option key={empleado.empleado_id} value={empleado.empleado_id}>
                            {empleado.nombre} {empleado.apellido}
                        </option>
                    ))}
                </select>
            </div>
            
            {selectedEmpleado && <form onSubmit={handleSubmit} className="space-y-6">
                {diasSemana.map(dia => (
                    <div key={dia} className={`flex flex-col sm:flex-row items-center justify-between space-y-4 sm:space-y-0 sm:space-x-4 p-4 rounded-lg shadow-md ${horarios[dia] && horarios[dia].active ? 'bg-green-100 border-l-4 border-green-500' : 'bg-red-100 border-l-4 border-red-500'}`}>
                        <div className="flex items-center w-full sm:w-auto">
                            <input
                                type="checkbox"
                                checked={horarios[dia] && horarios[dia].active}
                                onChange={e => handleDiaChange(dia, e.target.checked)}
                                className="mr-2 h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                            />
                            <span className={`w-32 font-medium ${horarios[dia] && horarios[dia].active ? 'text-green-800' : 'text-red-800'}`}>{dia}</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <TimeSelector
                                label="Hora Inicio"
                                value={horarios[dia] ? horarios[dia].hora_inicio : '00'}
                                onChange={e => handleTimeChange(dia, 'hora_inicio', e.target.value)}
                                options={Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'))}
                                disabled={!horarios[dia] || !horarios[dia].active}
                            />
                            <TimeSelector
                                label="Minuto Inicio"
                                value={horarios[dia] ? horarios[dia].minuto_inicio : '00'}
                                onChange={e => handleTimeChange(dia, 'minuto_inicio', e.target.value)}
                                options={['00', '30']}
                                disabled={!horarios[dia] || !horarios[dia].active}
                            />
                            <span className="text-xl mx-4">a</span>
                            <TimeSelector
                                label="Hora Fin"
                                value={horarios[dia] ? horarios[dia].hora_fin : '00'}
                                onChange={e => handleTimeChange(dia, 'hora_fin', e.target.value)}
                                options={Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'))}
                                disabled={!horarios[dia] || !horarios[dia].active}
                            />
                            <TimeSelector
                                label="Minuto Fin"
                                value={horarios[dia] ? horarios[dia].minuto_fin : '00'}
                                onChange={e => handleTimeChange(dia, 'minuto_fin', e.target.value)}
                                options={['00', '30']}
                                disabled={!horarios[dia] || !horarios[dia].active}
                            />
                        </div>
                    </div>
                ))}
                <button
                    type="submit"
                    className="w-full py-2 bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150"
                >
                    Guardar Horarios
                </button>
            </form>}
        </div>
    );
}

export default HorariosEmpleados;
