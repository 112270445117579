import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import AppointmentDetails from './AppointmentDetails';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWithAuth } from '../utils/apiHelper';

const ROW_HEIGHT = 50;
const HOUR_CELL_WIDTH = 100;
const CELL_WIDTH = 250;


const ScheduleTable = ({ appointments, employees, onBookingClick, dia, sucursal, updateAppointments, empleados, paymentMethods, servicios }) => {
  const [updatedAppointments, setUpdatedAppointments] = useState([]);
  const [loadingState, setLoadingState] = useState({});
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  useEffect(() => {
    setUpdatedAppointments(appointments || []);
  }, [appointments]);

  useEffect(() => {
    if (sucursal?.hora_inicio && sucursal?.hora_fin) {
      setStartTime(sucursal.hora_inicio.slice(0, 5));
      setEndTime(sucursal.hora_fin.slice(0, 5));
    }
  }, [sucursal]);

  const generateTimeSlots = useCallback((start, end) => {
    const slots = [];
    let current = moment(start, 'HH:mm');
    const endTime = moment(end, 'HH:mm');

    while (current.isBefore(endTime)) {
      slots.push(current.format('HH:mm'));
      current.add(30, 'minutes');
    }
    return slots;
  }, []);

  const timeSlots = startTime && endTime ? generateTimeSlots(startTime, endTime) : [];

  const isEmployeeWorking = useCallback((time, employee) => {
    const currentTime = moment(time, 'HH:mm');
    return employee.horarios.some(horario => {
      const startTime = moment(horario.hora_inicio, 'HH:mm');
      const endTime = moment(horario.hora_fin, 'HH:mm');
      return horario.dia_semana.toLowerCase() === dia.toLowerCase() && currentTime.isBetween(startTime, endTime, null, '[)');
    });
  }, [dia]);

  const getAppointmentsForTime = useCallback((time, employeeId) => {
    if (!updatedAppointments) return [];
    return updatedAppointments.filter(appointment => {
      const startTime = moment(`${appointment.fecha}T${appointment.hora}`);
      const durationMinutes = (appointment.duracion.hours || 0) * 60 + (appointment.duracion.minutes || 0);
      const endTime = startTime.clone().add(durationMinutes, 'minutes');
      const currentTime = moment(time, 'HH:mm');
      currentTime.set({ year: startTime.year(), month: startTime.month(), date: startTime.date() });
      return appointment.empleado_id === employeeId && currentTime.isBetween(startTime, endTime, null, '[)');
    });
  }, [updatedAppointments]);

  const handleConfirm = async (id) => {
    setLoadingState({ [id]: 'confirming' });
    try {
      await fetchWithAuth('/turnos/confirmar/turno', {
        method: 'POST',
        body: JSON.stringify({ turno_id: id }),
      });
      toast.success('Turno confirmado exitosamente');
      updateAppointments();
    } catch (error) {
      console.error('Error confirming appointment:', error);
      toast.error('Error al confirmar el turno: ' + error.message);
    } finally {
      setLoadingState({});
    }
  };

  const handleCancel = async (id, cancelReason) => {
    setLoadingState({ [id]: 'canceling' });
    try {
      await fetchWithAuth('/turnos/cancelar/turno', {
        method: 'POST',
        body: JSON.stringify({ turno_id: id, motivo_cancelacion: cancelReason }),
      });
      toast.success('Turno cancelado exitosamente');
      updateAppointments();
    } catch (error) {
      console.error('Error canceling appointment:', error);
      toast.error('Error al cancelar el turno: ' + error.message);
    } finally {
      setLoadingState({});
    }
  };

  const handlePay = async (id, paymentMethod, selectedServices) => {
    setLoadingState({ [id]: 'paying' });
    try {
      await fetchWithAuth('/operaciones/crear/pago', {
        method: 'POST',
        body: JSON.stringify({ turno_id: id, metodo_pago: paymentMethod, servicios: selectedServices }),
      });
      toast.success('Pago realizado exitosamente');
      updateAppointments();
    } catch (error) {
      console.error(error);
      toast.error('Error al realizar el pago: ' + error.message);
    } finally {
      setLoadingState({});
    }
  };

  const occupiedSlots = {};

  return (
    <div className="mt-6 max-w-full overflow-x-auto">
      <div className="max-w-full max-h-[800px] relative shadow-lg rounded-lg bg-gray-50">
        <table className="w-full table-fixed bg-gray-100 rounded-lg">
          <thead className="bg-teal-500 text-white">
            <tr>
              <th className="px-2 py-3 text-center text-sm font-semibold rounded-l-lg sticky top-0 left-0 bg-teal-500 z-20" style={{ width: `${HOUR_CELL_WIDTH}px` }}>
                Hora
              </th>
              {employees.map((employee, index) => (
                <th 
                  key={employee.empleado_id} 
                  className="px-4 py-3 text-center text-sm font-semibold sticky top-0 left-0 bg-teal-500 z-30" 
                  style={{ width: `${CELL_WIDTH}px` }}
                >
                  {employee.nombre} {employee.apellido}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {timeSlots.length === 0 ? (
              <tr>
                <td colSpan={employees.length + 1} className="text-center py-4 text-gray-500">Cargando horarios...</td>
              </tr>
            ) : timeSlots.map((time, index) => (
              <tr key={index} style={{ height: `${ROW_HEIGHT}px` }}>
                <td className="px-2 py-2 text-center text-sm font-medium bg-teal-100 border-2 border-teal-500 sticky left-0 z-10" style={{ width: `${HOUR_CELL_WIDTH}px` }}>
                  {time}
                </td>
                {employees.map((employee) => {
                  const working = isEmployeeWorking(time, employee);
                  const empAppointments = getAppointmentsForTime(time, employee.empleado_id);

                  if (empAppointments.length > 0) {
                    const appointment = empAppointments[0];
                    const startTime = moment(`${appointment.fecha}T${appointment.hora}`);
                    const durationMinutes = (appointment.duracion.hours || 0) * 60 + (appointment.duracion.minutes || 0);
                    const rowSpan = Math.ceil(durationMinutes / 30);
                    if (occupiedSlots[`${employee.empleado_id}-${index}`]) {
                      return null;
                    }
                    for (let i = 0; i < rowSpan; i++) {
                      occupiedSlots[`${employee.empleado_id}-${index + i}`] = true;
                    }
                    return (
                      <td key={appointment.turno_id} rowSpan={rowSpan} className="p-0 border-t-2 border-b-2 border-teal-500 bg-teal-200" style={{ height: `${ROW_HEIGHT * rowSpan}px`, width: `${CELL_WIDTH}px` }}>
                        <AppointmentDetails 
                          appointment={appointment} 
                          onConfirm={handleConfirm} 
                          onCancel={handleCancel} 
                          onPay={handlePay}
                          empleados={empleados}
                          paymentMethods={paymentMethods}
                          servicios={servicios}
                          loadingState={loadingState[appointment.turno_id]}
                          className="h-full w-full rounded-md"
                        />
                      </td>
                    );
                  }
                  
                  return (
                    <td key={employee.empleado_id} onClick={() => onBookingClick(employee, time)} className={`text-center text-sm font-medium border-t-2 border-b-2 border-teal-500 ${working ? 'bg-green-100 hover:bg-green-200' : 'bg-red-50 hover:bg-red-100'} cursor-pointer`} style={{ width: `${CELL_WIDTH}px`, height: `${ROW_HEIGHT}px` }}>
                      <span className={`${working ? 'text-teal-500' : 'text-red-500'}`}>{working ? 'Disponible' : 'No disponible'}</span>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ScheduleTable;
