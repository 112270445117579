import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ModalMovimientos from './ModalMovimientos';
import OperacionesPagosModal from './OperacionesPagosModal';

function Navbar() {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [sucursalNombre, setSucursalNombre] = useState('');
  const [admin, setAdmin] = useState(false);
  const [nombreUsuario, setNombreUsuario] = useState('');
  const [isMovimientosModalOpen, setIsMovimientosModalOpen] = useState(false);
  const [isOperacionesPagosModalOpen, setIsOperacionesPagosModalOpen] = useState(false);
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const nombre = localStorage.getItem('sucursalNombre');
    const username = localStorage.getItem('nombreUsuario');
    const isAdmin = localStorage.getItem('admin');
    
    if (username) {
      const formattedUsername = username.charAt(0).toUpperCase() + username.slice(1).toLowerCase();
      setNombreUsuario(formattedUsername);
    }
    if (nombre) {
      setSucursalNombre(nombre);
    }
    if (isAdmin !== null) {
      setAdmin(isAdmin === 'true');
    }
  }, []);

  const handleDropdown = (dropdown) => {
    setOpenDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload();
  };

  const handleOptionClick = () => {
    setOpenDropdown(null);
    setIsMobileDropdownOpen(false);
  };

  const openMovimientosModal = () => {
    setIsMovimientosModalOpen(true);
  };

  const closeMovimientosModal = () => {
    setIsMovimientosModalOpen(false);
  };

  const openOperacionesPagosModal = () => {
    setIsOperacionesPagosModalOpen(true);
  };

  const closeOperacionesPagosModal = () => {
    setIsOperacionesPagosModalOpen(false);
  };

  const toggleMobileDropdown = () => {
    setIsMobileDropdownOpen((prev) => !prev);
    setOpenDropdown(null); // Close other dropdowns when opening mobile menu
  };

  return (
    <div className="z-50 pb-20">
      <nav className="bg-teal-600 text-white w-full shadow-lg relative"> {/* Fondo color teal */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Link to="/" className="text-2xl font-bold text-white">{sucursalNombre}</Link>
              </div>
              <div className="md:hidden ml-auto">
                <button
                  onClick={toggleMobileDropdown}
                  className="text-white hover:text-gray-300 focus:outline-none"
                >
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div className="hidden md:block ml-10">
              <div className="flex items-baseline space-x-4">
                <div className="relative group">
                  <button
                    className="nav-link text-white hover:text-teal-200"
                    onMouseEnter={() => handleDropdown('inicio')}
                    onClick={() => handleDropdown('inicio')}
                  >
                    Inicio
                  </button>
                  {openDropdown === 'inicio' && (
                    <div className="absolute left-0 mt-2 w-48 bg-teal-600 text-white border border-teal-400 rounded-md shadow-lg">
                      <Link className="block px-4 py-2 hover:bg-teal-500" to="/Servicios" onClick={handleOptionClick}>Lista de servicios</Link>
                      <Link className="block px-4 py-2 hover:bg-teal-500" to="/clientes" onClick={handleOptionClick}>Clientes</Link>
                      <Link className="block px-4 py-2 hover:bg-teal-500" to="/" onClick={handleOptionClick}>Turnos</Link>
                      <Link className="block px-4 py-2 hover:bg-teal-500" to="/" onClick={handleLogout}>Salir</Link>
                    </div>
                  )}
                </div>
                {admin && (
                  <div className="relative group">
                    <button
                      className="nav-link text-white hover:text-teal-200"
                      onMouseEnter={() => handleDropdown('administracion')}
                      onClick={() => handleDropdown('administracion')}
                    >
                      Administración
                    </button>
                    {openDropdown === 'administracion' && (
                      <div className="absolute left-0 z-50 mt-2 w-48 bg-teal-600 text-white border border-teal-400 rounded-md shadow-lg">
                        <Link className="block px-4 py-2 hover:bg-teal-500" to="/Gestion/empleados" onClick={handleOptionClick}>Empleados</Link>
                        <Link className="block px-4 py-2 hover:bg-teal-500" to="/Gestion/Horarios" onClick={handleOptionClick}>Horarios</Link>
                        <Link className="block px-4 py-2 hover:bg-teal-500" to="/gestion/metodos/pago" onClick={handleOptionClick}>Metodos Pagos</Link>
                        <Link className="block px-4 py-2 hover:bg-teal-500" to="/Gestion/Comisiones" onClick={handleOptionClick}>Comisiones</Link>
                        <Link className="block px-4 py-2 hover:bg-teal-500" to="/Gestion/liquidacion" onClick={handleOptionClick}>Liquidaciones</Link>
                        <Link className="block px-4 py-2 hover:bg-teal-500" to="/trabajos/Caja" onClick={handleOptionClick}>Caja Fuerte</Link>
                        <Link className="block px-4 py-2 hover:bg-teal-500" to="/caja/fuerte" onClick={handleOptionClick}>Operaciones de Caja</Link>
                      </div>
                    )}
                  </div>
                )}
                <Link className="nav-link text-white hover:text-teal-200" onClick={openMovimientosModal}>Operaciones</Link>
                <Link className="nav-link text-white hover:text-teal-200" onClick={openOperacionesPagosModal}>Caja</Link>
              </div>
            </div>
            <div className="hidden md:block">
              <span className="text-lg font-semibold text-white ml-4">{nombreUsuario}</span>
            </div>
          </div>
        </div>
      </nav>
      {isMobileDropdownOpen && (
        <div className="md:hidden bg-teal-600 text-white border-t border-teal-700 shadow-lg">
          <div className="max-w-7xl mx-auto px-4 py-4">
            <h2 className="text-lg font-bold text-white mb-2">Inicio</h2>
            <Link className="block px-4 py-2 hover:bg-teal-500" to="/Servicios" onClick={handleOptionClick}>Lista de servicios</Link>
            <Link className="block px-4 py-2 hover:bg-teal-500" to="/clientes" onClick={handleOptionClick}>Clientes</Link>
            <Link className="block px-4 py-2 hover:bg-teal-500" to="/" onClick={handleOptionClick}>Turnos</Link>
            <Link className="block px-4 py-2 hover:bg-teal-500" to="/" onClick={handleLogout}>Salir</Link>
            {admin && (
              <>
                <h2 className="text-lg font-bold text-white mt-4 mb-2">Administración</h2>
                <Link className="block px-4 py-2 hover:bg-teal-500" to="/Gestion/empleados" onClick={handleOptionClick}>Empleados</Link>
                <Link className="block px-4 py-2 hover:bg-teal-500" to="/Gestion/Horarios" onClick={handleOptionClick}>Horarios</Link>
                <Link className="block px-4 py-2 hover:bg-teal-500" to="/gestion/metodos/pago" onClick={handleOptionClick}>Metodos Pagos</Link>
                <Link className="block px-4 py-2 hover:bg-teal-500" to="/Gestion/Comisiones" onClick={handleOptionClick}>Comisiones</Link>
                <Link className="block px-4 py-2 hover:bg-teal-500" to="/Gestion/liquidacion" onClick={handleOptionClick}>Liquidaciones</Link>
                <Link className="block px-4 py-2 hover:bg-teal-500" to="/trabajos/Caja" onClick={handleOptionClick}>Caja Fuerte</Link>
                <Link className="block px-4 py-2 hover:bg-teal-500" to="/caja/fuerte" onClick={handleOptionClick}>Operaciones de Caja</Link>
              </>
            )}
            <h2 className="text-lg font-bold text-white mt-4 mb-2">Opciones Adicionales</h2>
            <Link className="block px-4 py-2 hover:bg-teal-500" onClick={openMovimientosModal}>Operaciones</Link>
            <Link className="block px-4 py-2 hover:bg-teal-500" onClick={openOperacionesPagosModal}>Caja</Link>
          </div>
        </div>
      )}
      <ModalMovimientos isOpen={isMovimientosModalOpen} onClose={closeMovimientosModal} />
      <OperacionesPagosModal isOpen={isOperacionesPagosModalOpen} onRequestClose={closeOperacionesPagosModal} />
    </div>
  );
}

export default Navbar;
