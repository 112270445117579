import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from '../utils/apiHelper';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const LiquidacionesResumen = () => {
  const [liquidaciones, setLiquidaciones] = useState([]);
  const [selectedEmpleado, setSelectedEmpleado] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const today = new Date();
    setStartDate(new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0]);
    setEndDate(new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0]);
  }, []);

  const fetchLiquidaciones = async () => {
    setLoading(true);
    try {
      const response = await fetchWithAuth(`/operaciones/obtener/liquidaciones?startDate=${startDate}&endDate=${endDate}`);
      setLiquidaciones(Array.isArray(response) ? response : []);
      console.log(response)
    } catch (error) {
      toast.error('Error al obtener las liquidaciones');
      console.error('Error fetching liquidaciones:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculateTotals = (liquidaciones) => {
    return liquidaciones.reduce((totals, empleado) => {
      // Calculate from trabajos
      empleado.trabajos.forEach(trabajo => {
        trabajo.servicios.forEach(servicio => {
          totals.facturado += servicio.servicio_precio || 0;
          totals.comisiones += servicio.comision || 0;
        });
      });
      // Include comisiones directly associated with the employee
      totals.comisiones += empleado.comisiones.reduce((sum, comision) => sum + (comision.monto || 0), 0);
      
      totals.vales += empleado.vales.reduce((sum, vale) => sum + (vale.monto || 0), 0);
      totals.viaticos += empleado.viaticos.reduce((sum, viatico) => sum + (viatico.monto || 0), 0);
      totals.aPagar += totals.comisiones + totals.viaticos - totals.vales;
      return totals;
    }, { facturado: 0, comisiones: 0, vales: 0, viaticos: 0, aPagar: 0 });
  };

  const generatePDF = () => {
    if (!selectedEmpleado) return;
  
    const doc = new jsPDF();
  
    doc.setFontSize(18);
    doc.text(`${selectedEmpleado.empleado_nombre} ${selectedEmpleado.empleado_apellido}`, 14, 22);
    doc.setFontSize(12);
    doc.text(`Fecha de inicio: ${startDate}`, 14, 36);
    doc.text(`Fecha de fin: ${endDate}`, 14, 42);
  
    let yOffset = 50; // Initial offset for first table
  
    // Servicios section
    const servicioColumn = ["Fecha", "Servicios", "Importe liquidado"];
    const servicioRows = selectedEmpleado.trabajos.flatMap(trabajo =>
      trabajo.servicios.map(servicio => [
        new Date(trabajo.fecha).toLocaleDateString(),
        servicio.servicio_nombre,
        `$${(servicio.comision || 0).toLocaleString()}`
      ])
    );
  
    doc.autoTable({
      head: [servicioColumn],
      body: servicioRows,
      startY: yOffset,
    });
  
    yOffset = doc.lastAutoTable.finalY + 10; // Update yOffset
  
    // Vales section
    if (selectedEmpleado.vales.length > 0) {
      const valeColumn = ["Fecha", "Descripción", "Monto"];
      const valeRows = selectedEmpleado.vales.map(vale => [
        new Date(vale.fecha_operacion).toLocaleDateString(),
        vale.descripcion,
        `$${(vale.monto || 0).toLocaleString()}`
      ]);
  
      doc.autoTable({
        head: [valeColumn],
        body: valeRows,
        startY: yOffset,
      });
  
      yOffset = doc.lastAutoTable.finalY + 10; // Update yOffset
    }
  
    // Viáticos section
    if (selectedEmpleado.viaticos.length > 0) {
      const viaticoColumn = ["Fecha", "Descripción", "Monto"];
      const viaticoRows = selectedEmpleado.viaticos.map(viatico => [
        new Date(viatico.fecha_operacion).toLocaleDateString(),
        viatico.descripcion,
        `$${(viatico.monto || 0).toLocaleString()}`
      ]);
  
      doc.autoTable({
        head: [viaticoColumn],
        body: viaticoRows,
        startY: yOffset,
      });
  
      yOffset = doc.lastAutoTable.finalY + 10; // Update yOffset
    }
  
    // Comisiones section
    if (selectedEmpleado.comisiones.length > 0) {
      const comisionColumn = ["Fecha", "Descripción", "Monto"];
      const comisionRows = selectedEmpleado.comisiones.map(comision => [
        new Date(comision.fecha_operacion).toLocaleDateString(),
        comision.descripcion,
        `$${(comision.monto || 0).toLocaleString()}`
      ]);
  
      doc.autoTable({
        head: [comisionColumn],
        body: comisionRows,
        startY: yOffset,
      });
  
      yOffset = doc.lastAutoTable.finalY + 10; // Update yOffset
    }
  
    // Add total section
    const totalImporteLiquidado = selectedEmpleado.trabajos.reduce((sum, trabajo) =>
      sum + trabajo.servicios.reduce((sSum, servicio) =>
        sSum + (servicio.comision || 0), 0), 0);
    const totalVales = selectedEmpleado.vales.reduce((sum, vale) => sum + (vale.monto || 0), 0);
    const totalViaticos = selectedEmpleado.viaticos.reduce((sum, viatico) => sum + (viatico.monto || 0), 0);
    const totalComisiones = selectedEmpleado.comisiones.reduce((sum, comision) => sum + (comision.monto || 0), 0);
  
    const totalAPagar = totalImporteLiquidado + totalViaticos - totalVales + totalComisiones;
  
    doc.setFontSize(14);

    doc.text(`Total: $${totalAPagar.toLocaleString()}`, 14, yOffset);
  
    doc.save(`${selectedEmpleado.empleado_nombre}_${selectedEmpleado.empleado_apellido}.pdf`);
  };
  

  const totals = calculateTotals(liquidaciones);

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Resumen de Liquidaciones</h2>
      <div className="mb-6 flex flex-wrap justify-center space-y-4 sm:space-y-0 sm:space-x-4">
        <div>
          <label className="block text-gray-700 font-semibold mb-1">Fecha de inicio:</label>
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="p-2 border rounded w-full" />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-1">Fecha de fin:</label>
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="p-2 border rounded w-full" />
        </div>
        <button
          onClick={fetchLiquidaciones}
          disabled={loading}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded flex items-center"
        >
          {loading ? <ClipLoader size={20} color={"#fff"} /> : 'Buscar'}
        </button>
      </div>
      <div className="overflow-x-auto rounded-lg shadow-md">
        <table className="min-w-full bg-white rounded-lg">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-600 uppercase">Empleado</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-600 uppercase">Facturado</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-600 uppercase">Comisiones</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-600 uppercase">Vales</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-600 uppercase">Viáticos</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-600 uppercase">A pagar</th>
            </tr>
          </thead>
          <tbody>
            {liquidaciones.map((empleado, index) => {
              const facturado = empleado.trabajos.reduce((sum, trabajo) => sum + trabajo.servicios.reduce((sSum, servicio) => sSum + (servicio.servicio_precio || 0), 0), 0);
              const comisiones = empleado.trabajos.reduce((sum, trabajo) => sum + trabajo.servicios.reduce((sSum, servicio) => sSum + (servicio.comision || 0), 0), 0)
                               + empleado.comisiones.reduce((sum, comision) => sum + (comision.monto || 0), 0);;
              const vales = empleado.vales.reduce((sum, vale) => sum + (vale.monto || 0), 0);
              const viaticos = empleado.viaticos.reduce((sum, viatico) => sum + (viatico.monto || 0), 0);
              const aPagar = comisiones + viaticos - vales;

              return (
                <tr key={empleado.empleado_id} onClick={() => setSelectedEmpleado(empleado)} className={`cursor-pointer ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition duration-150`}>
                  <td className="border px-6 py-4">{empleado.empleado_nombre} {empleado.empleado_apellido}</td>
                  <td className="border px-6 py-4">${facturado.toLocaleString()}</td>
                  <td className="border px-6 py-4">${comisiones.toLocaleString()}</td>
                  <td className="border px-6 py-4">${vales.toLocaleString()}</td>
                  <td className="border px-6 py-4">${viaticos.toLocaleString()}</td>
                  <td className="border px-6 py-4">${aPagar.toLocaleString()}</td>
                </tr>
              );
            })}
            <tr className="font-bold bg-gray-200">
              <td className="border px-6 py-4">Totales</td>
              <td className="border px-6 py-4">${totals.facturado.toLocaleString()}</td>
              <td className="border px-6 py-4">${totals.comisiones.toLocaleString()}</td>
              <td className="border px-6 py-4">${totals.vales.toLocaleString()}</td>
              <td className="border px-6 py-4">${totals.viaticos.toLocaleString()}</td>
              <td className="border px-6 py-4">${totals.aPagar.toLocaleString()}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {selectedEmpleado && (
        <div className="mt-8 bg-white p-6 rounded-lg shadow-md">
          <div className="flex justify-between">
            <h3 className="text-2xl font-bold mb-6">Detalles de {selectedEmpleado.empleado_nombre} {selectedEmpleado.empleado_apellido}</h3>
            <button
              onClick={generatePDF}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-150"
            >
              Descargar PDF
            </button>
          </div>
          <div className="overflow-x-auto rounded-lg shadow-md">
            <table className="min-w-full bg-white">
              <thead className="bg-gray-200">
                <tr>
                  <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-600 uppercase">Fecha</th>
                  <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-600 uppercase">Servicios</th>
                  <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-600 uppercase">Cliente</th>
                  <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-600 uppercase">Precio de lista</th>
                  <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-600 uppercase">Importe liquidado</th>
                  <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-600 uppercase">Comisión</th>
                </tr>
              </thead>
              <tbody>
                {selectedEmpleado.trabajos.map((trabajo, index) => (
                  <React.Fragment key={index}>
                    {trabajo.servicios.map((servicio, sIndex) => (
                      <tr key={sIndex} className={`cursor-pointer ${sIndex % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition duration-150`}>
                        {sIndex === 0 && (
                          <td className="border px-6 py-4" rowSpan={trabajo.servicios.length}>
                            {new Date(trabajo.fecha).toLocaleDateString()}
                          </td>
                        )}
                        <td className="border px-6 py-4">{servicio.servicio_nombre}</td>
                        {sIndex === 0 && (
                          <td className="border px-6 py-4" rowSpan={trabajo.servicios.length}>
                            {trabajo.cliente_nombre} {trabajo.cliente_apellido}
                          </td>
                        )}
                        <td className="border px-6 py-4">${servicio.servicio_precio?.toLocaleString() || '0'}</td>
                        <td className="border px-6 py-4">${servicio.comision?.toLocaleString() || '0'}</td>
                        <td className="border px-6 py-4">{servicio.porcentaje_comision}%</td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
                <tr className="font-bold bg-gray-200">
                  <td className="border px-6 py-4" colSpan="6">Vales</td>
                </tr>
                {selectedEmpleado.vales.map((vale, index) => (
                  <tr key={index} className={`cursor-pointer ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition duration-150`}>
                    <td className="border px-6 py-4">{new Date(vale.fecha_operacion).toLocaleDateString()}</td>
                    <td className="border px-6 py-4" colSpan="3">{vale.descripcion}</td>
                    <td className="border px-6 py-4">${vale.monto?.toLocaleString() || '0'}</td>
                    <td className="border px-6 py-4">{vale.comentario}</td>
                  </tr>
                ))}
                <tr className="font-bold bg-gray-200">
                  <td className="border px-6 py-4" colSpan="6">Viáticos</td>
                </tr>
                {selectedEmpleado.viaticos.map((viatico, index) => (
                  <tr key={index} className={`cursor-pointer ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition duration-150`}>
                    <td className="border px-6 py-4">{new Date(viatico.fecha_operacion).toLocaleDateString()}</td>
                    <td className="border px-6 py-4" colSpan="3">{viatico.descripcion}</td>
                    <td className="border px-6 py-4">${viatico.monto?.toLocaleString() || '0'}</td>
                    <td className="border px-6 py-4">{viatico.comentario}</td>
                  </tr>
                ))}
                <tr className="font-bold bg-gray-200">
                  <td className="border px-6 py-4" colSpan="6">Comisiones</td>
                </tr>
                {selectedEmpleado.comisiones.map((comision, index) => (
                  <tr key={index} className={`cursor-pointer ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition duration-150`}>
                    <td className="border px-6 py-4">{new Date(comision.fecha_operacion).toLocaleDateString()}</td>
                    <td className="border px-6 py-4" colSpan="3">{comision.descripcion}</td>
                    <td className="border px-6 py-4">${comision.monto?.toLocaleString() || '0'}</td>
                    <td className="border px-6 py-4">{comision.comentario}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            onClick={() => setSelectedEmpleado(null)}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-150"
          >
            Cerrar
          </button>
        </div>
      )}
    </div>
  );
};

export default LiquidacionesResumen;
